<template>
	<!-- eslint-disable -->
	<v-container v-if="hasPermission($route.meta.permission)">
		<v-row class="ma-0">
			<v-col cols="12"><span class="text-h2">Invoices</span></v-col>

			<v-col cols="12" sm="3" md="3">
				<v-menu
					ref="delivery_date_menu"
					v-model="dateRangeMenue"
					:close-on-content-click="false"
					:return-value.sync="date"
					transition="scale-transition"
					min-width="auto"
					offset-y
					dense
				>
					<template #activator="{ on, attrs }">
						<v-text-field
							:value="dateRangeText"
							label="Pick a date range"
							range
							dense
							prepend-inner-icon="mdi-calendar"
							class="white"
							:append-icon="dateRangeText && 'mdi-close'"
							@click:append="ClearDateFilter"
							outlined
							hide-details
							v-bind="attrs"
							v-on="on"
						>
							>
						</v-text-field>
					</template>
					<v-date-picker
						class="ma-0"
						color="munjz-accent"
						v-model="dates"
						range
						type="month"
						dense
					>
						<v-spacer></v-spacer>

						<v-btn text color="primary" @click="dateRangeMenue = false">
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
			</v-col>
			<v-col cols="12" sm="3" md="3">
				<v-autocomplete
					v-model="selectedInvoiceStatus"
					:items="invoiceStatusFilterOptions"
					class="white"
					outlined
					deletable-chips
					clearable
					dense
					hide-details
					item-text="statusText"
					item-value="id"
					label="All Status"
				>
					<template #selection="data">
						<v-chip
							v-bind="data.attrs"
							:input-value="data.selected"
							small
							outlined
							:color="data.item.color"
							dense
							@click="data.select"
							>{{ data.item.statusText }}
						</v-chip>
					</template>
				</v-autocomplete>
			</v-col>
		</v-row>
		<v-row class="ma-0">
			<v-col cols="12">
				<v-card flat>
					<v-data-table
						dense
						show-expand
						single-expand
						:headers="headers"
						:items="invoices"
						:loading="loading"
						loading-text="Loading... Please wait"
						no-data-text="You don't have any invoices"
					>
						<template #item.data-table-expand="{expand,isExpanded}">
							<v-icon @click="isExpanded ? expand(false) : expand(true)">
								mdi-{{ isExpanded ? "chevron-down" : "chevron-right" }}</v-icon
							>
						</template>
						<template transition="in-out" #expanded-item="{ headers, item }">
							<td
								:colspan="headers.length"
								class="d-flex-cols pa-0   justify-center"
							>
								<div class="primary " style="width:100%;height:2px"></div>
								<v-card
									transition="in-out"
									flat
									class="pa-3 d-flex justify-center justify-sm-start"
								>
									<v-col cols="12" sm="3" class="">
										<div style="width:max-content" class="justify-center ">
											<div class="d-flex justify-end ">
												<h4 class="grey--text me-5">Total Amount</h4>

												<h4>
													{{ item.amount }}<span class="grey--text"> RS</span>
												</h4>
											</div>

											<div class="d-flex justify-end">
												<h4 class="grey--text me-5">VAT</h4>

												<h4>
													{{ item.vat.toFixed(1)
													}}<span class="grey--text"> SAR</span>
												</h4>
											</div>
											<div class="d-flex justify-end">
												<h4 class="grey--text me-5">Paid Amount</h4>

												<h4>
													{{ item.paid_amount
													}}<span class="grey--text"> SAR</span>
												</h4>
											</div>

											<div class="d-flex justify-end">
												<h4 class="grey--text me-5">Total</h4>

												<h4>
													{{ item.amount }}<span class="grey--text"> SAR</span>
												</h4>
											</div>

											<v-card-actions class="d-flex justify-end">
												<v-btn
													small
													@click="goToInvoiceDetail(item.id)"
													class="rounded-pill me-0"
													color="primary"
													>{{
														item.status !== 1 && item.status !== 3
															? "Send Payment Receipt"
															: "View Receipt"
													}}
													<v-icon right color="white">{{
														item.status !== 1 && item.status !== 3
															? "mdi-send"
															: "mdi-eye"
													}}</v-icon>
												</v-btn>
											</v-card-actions>
										</div>
									</v-col>
								</v-card>
							</td>
						</template>

						<template #item.status="{ item }">
							<v-chip small outlined :color="invoiceStatus[item.status].color">
								{{ invoiceStatus[item.status].statusText }}
							</v-chip>
						</template>

						<template #item.created_at="{ item }">
							{{ moment(item.created_at).format("MMM YYYY") }}
						</template>

						<template #item.more_actions="{ item }">
							<v-menu left :close-on-click="true">
								<template #activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on">
										mdi-dots-vertical
									</v-icon>
								</template>

								<v-list>
									<v-list-item @click="showInvoiceDetail(item)">
										<v-list-item-icon>
											<v-icon>mdi-file</v-icon>
										</v-list-item-icon>

										Export invoice as PDF
									</v-list-item>

									<v-list-item @click="downloadOrdersExcel(item)">
										<download-excel
											:data="excelOrders"
											worksheet=""
											:fields="jsonFields"
											:name="`${invoiceName}-Invoice.xls`"
										>
											<v-list-item-icon>
												<v-icon>mdi-note-plus</v-icon>
											</v-list-item-icon>
											Export orders as excel
										</download-excel>
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
					</v-data-table>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<v-container fluid v-else class="pa-10 " id="regular-tables" tag="section">
		<v-row>
			<v-col cols="12" class="d-flex justify-center">
				<v-card flat>
					<!-- <v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have Permission to view this page !
							</h1></v-card
						></v-card-title
					> -->
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
/* eslint-disable */
import moment from "moment";

export default {
	data() {
		return {
			dateRangeMenue: false,
			date: null,
			dates: [],
			invoices: [],
			invoice: {},
			invoiceName: "",
			excelOrders: [],
			jsonFields: {
				"Order No.": "order_no",
				"Order Name": "main_service",
				"Total price": "final_total_price",
				"Total price with Avat": "final_total_price_with_vat",
				"Seller Name": "seller_name",
				"Branch Name": "branch_name"
			},
			loading: true,
			token: localStorage.getItem("token"),
			moment: moment,
			headers: [
				{
					text: "export",
					align: "center",

					value: "created_at"
				},
				{
					text: "status",
					align: "center",

					value: "status"
				},
				{
					text: "invoice#",
					align: "center",

					value: "partner_invoice_no"
				},

				{
					text: "amount",
					align: "center",

					value: "amount"
				},
				{
					text: "VAT",
					align: "center",

					value: "vat"
				},
				{
					text: "Paid Amount",
					align: "center",

					value: "paid_amount"
				},

				{
					text: "Payment date",
					align: "center",

					value: "payment_date"
				},

				{
					text: "",
					align: "center",
					sortable: false,

					value: "more_actions"
				}
			],
			selectedInvoiceStatus: null,
			invoiceStatusFilterOptions: [
				{
					id: 1,
					color: "#00ff48",
					statusText: "paid"
				},
				{
					id: 2,
					color: "red",
					statusText: "unpaid"
				}
			],
			invoiceStatus: [
				{
					id: 0,
					color: "red",
					statusText: "unpaid"
				},
				{
					id: 1,
					color: "#00ff48",
					statusText: "paid"
				},
				{
					id: 2,
					color: "red",
					statusText: "unpaid"
				},

				{
					id: 3,
					color: "red",
					statusText: "unpaid"
				},
				{
					id: 4,
					color: "red",
					statusText: "unpaid"
				}
			],
			invoiceDateToDialog: false,
			invoiceDateTo: null,
			invoiceDateFromDialog: false,
			invoiceDateFrom: null
		};
	},
	computed: {},
	watch: {
		selectedInvoiceStatus: {
			handler() {
				this.getInvoices();
			},
			deep: true
		},
		invoiceDateFrom: {
			handler() {
				this.getInvoices();
			},
			deep: true
		},
		dates: {
			handler() {
				this.getInvoices();
			},
			deep: true
		}
	},
	mounted() {
		console.log('this.invoiceStatus :>> ', this.invoiceStatus);
		this.getAdminPermissions();
		this.getInvoices();
		
	},
	methods: {

		goToInvoiceDetail(id) {
			this.$router.push({ path: `/invoice-detail/${id}` });
		},
		ClearDateFilter() {
			this.dates = [];
			this.dateRangeMenue = false;
		},
		getInvoices() {
			axios
				.get("/api/v3/b2b/partner-invoices", {
					headers: { "X-Authorization": this.token },
					params: {
						partner_id: this.user_id,
						from: this.dates[0],
						to: this.dates[1],
						status_id: this.selectedInvoiceStatus
					}
				})
				.then(res => {
					this.invoices = res.data.partner_invoices;
				})
				.catch(err => console.log(err))
				.finally(() => {
					this.dialog = false;
					this.loading = false;
				});
		},
		showInvoiceDetail(invoice) {
			localStorage.removeItem("invoice_detail");
			localStorage.setItem("invoice_detail", JSON.stringify(invoice));
			this.$router.push({
				path: `/invoice-detail/${invoice.id}`
			});
			setTimeout(() => {
				window.print();
			}, 1000);
		},
		downloadOrdersExcel(item) {
			this.getInvoiceOrders(item);
		},
		getInvoiceOrders(item) {
			axios
				.get(`/api/v3/b2b/partner-invoice-orders/${item.id}`, {
					headers: { "X-Authorization": this.token },
					params: {
						partner_id: this.user_id
					}
				})
				.then(res => {
					this.invoice = res.data;
					this.invoiceName = this.invoice.invoice.partner_invoice_no;
					this.excelOrders = this.invoice.invoice.orders;
				})
				.catch(err => console.log(err))
				.finally(() => {
					this.dialog = false;
				});
		}
	},
	computed: {
		dateRangeText() {
			return this.dates.join(" to ");
		}
	}
};
</script>
<style></style>
